<template>
  <div :class="['partnership', {'is-visible': visible}]">
    <button type="button" aria-label="Close" class="partnership__close" @click="dismiss">
      <i class="icon icon--close--pink" aria-hidden="true"></i>
    </button>
    
    <div class="partnership__profile">
      <div class="partnership__image">
        <img :src="activePartnership.image" :alt="activePartnership.name" />
      </div>
      <div class="partnership__details">
        <h5 class="name">{{ activePartnership.name }}</h5>
        <p class="company_type">{{ activePartnership.company_type }}</p>
      </div>
    </div>

    <div class="partnership__links" v-if="activePartnership.linkedin_url || activePartnership.website_url">
      <ul>
        <li v-if="activePartnership.linkedin_url">
          <a :href="activePartnership.linkedin_url" target="_blank"><i class="icon icon--linkedin--pink"></i></a>
        </li>
        <li v-if="activePartnership.website_url">
          <a :href="activePartnership.website_url" target="_blank"><i class="icon icon--website--pink"></i></a>
        </li>
      </ul>
      
    </div>

    <div class="partnership__body" v-html="activePartnership.body"></div>
  </div>
</template>

<script>
export default {
  props: {
    activePartnership: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  methods: {
    dismiss() {
      this.$emit("dismissProfile");
    }
  }
}
</script>

<style lang="scss" scoped>
  .partnership {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 500px;
    height: 100%;
    background: #16152E;
    padding: 80px 32px;
    overflow-y: hidden;

    /* this will hide the scrollbar in mozilla based browsers */
    overflow: -moz-scrollbars-none;
    /* this will hide the scrollbar in internet explorers */
    -ms-overflow-style: none;
    /* this will hide the scrollbar in webkit-based browsers */
    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }

    z-index: 4;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.6s cubic-bezier(.24,.01,.21,1);

    &.is-visible {
      transform: translate3d(0, 0, 0);
    }

    @media screen and (max-width: 500px) {
      width: auto;
      padding: 40px 20px;
    }

    &__close {
      position: absolute;
      top: 32px;
      right: 32px;
      padding: 0;
      cursor: pointer;

      @media screen and (max-width: 500px) {
        top: 18px;
        right: 18px;
      }
    }
  }

  .partnership__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .partnership__image {
    max-width: 150px;
    max-height: 100px;
    margin: 24px;
    overflow: hidden;
  }

  .partnership__details {
    margin-bottom: 36px;
    text-align: center;

    .name {
      font-family: 'Inter', Arial, Helvetica, sans-serif;
      font-style:  normal;
      font-weight: 700;
      
      font-size: 1.1875em;
      color: #FFF;
      line-height: 1.4;
    }

    .company_type {
      font-family: 'Inter', Arial, Helvetica, sans-serif;
      font-style:  normal;
      font-weight: 500;
      
      font-size: 1em;
      color: #B4B3C5;
      line-height: 1.5;
    }
  }

  .partnership__links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid rgba(#fff, 0.1);
    border-bottom: 1px solid rgba(#fff, 0.1);
    margin-bottom: 36px;

    ul {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: 32px;
      }
    }
  }

  .partnership__body {
    height: calc(100vh - 370px);
    color: #fff;
    margin-right: -20px;
    padding-right: 20px;
    font-size: 0.8125em;
    line-height: 1.92;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #16152E;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dfa9ff;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #dfa9ff;
    }    

    & > * + * {
      margin-top: 18px;
    }

  }
</style>
<template>
    <share-network
      network="linkedIn"
      :url="url"
      :popup="{width: 500, height: 500}"
    >
    <span class="icon icon--social--linkedin" />
  </share-network>
</template>

<script>
export default {
  props: {
    url: {type: String, required: true}
  }
}
</script>
<template>
  <div v-if="props">

    <div class="form__row" v-if="props.showAction && (!position || props.positionAction == position)">
      <div class="form__field">
        <label for="action">
          {{ props.actionLabel }}
          <span class="optional">(optional)</span>  
        </label>
        <select name="action"  id="action" :value="form_submission.action" @input="update('action', $event.target.value)">
          <option value="dxrx">Get help with DXRX</option>
          <option value="bug">Report a feedback/bug in our chat</option>
          <option value="privacy">Data Privacy queries</option>
          <option value="contact_us">Contact our Investor Relations team</option>
        </select>
        <div class="form__additional-options">
          <a href="https://help.dxrx.io" class="form__link" v-if="form_submission.action == 'dxrx'">
            Click here to view our help center knowledge base  
          </a>
        </div>
      </div>
    </div>

    <div class="form__row" v-if="props.showName && (!position || props.positionName == position)">
      <div class="form__field">
        <label for="name">{{props.name}}</label>
        <input type="text" name="name" id="name" required v-model="form_submission.name">
        <div class="error" v-if="errors.name">{{ errors.name }}</div>
      </div>
    </div>

    <div class="form__row" v-if="props.showTelephone && (!position || props.positionTelephone == position)">
      <div class="form__field">
        <label for="telephone">
          {{props.telephone}}
          <span class="optional">(optional)</span>  
        </label>
        <input type="text" name="telephone" id="telephone"  v-model="form_submission.telephone">
        <div class="error" v-if="errors.telephone">{{ errors.telephone }}</div>
      </div>
    </div>

    <div class="form__row" v-if="props.showEmail && (!position || props.positionEmail == position)">
      <div class="form__field">
        <label for="email">{{props.email}}</label>
        <input type="email" name="email" id="email" required v-model="form_submission.email">
        <div class="error" v-if="errors.email">{{ errors.email }}</div>
      </div>
    </div>

    <div class="form__row" v-if="props.showOrganisation && (!position || props.positionOrganisation == position)">
      <div class="form__field">
        <label for="organisation">
          {{props.organisation}}
        </label>
        <input type="text" name="organisation" id="organisation" required v-model="form_submission.organisation">
        <div class="error" v-if="errors.organisation">{{ errors.organisation }}</div>
      </div>
    </div>

    <div class="form__row" v-if="props.showIndustry && (!position || props.positionIndustry == position)">
      <div class="form__field">
        <label for="organisation">
          {{props.industry}}
        </label>
        <select name="industry" id="industry" required v-model="form_submission.industry">
          <option value="">Select</option>
          <option v-for="option in industryOptions" :key="option" :value="option">{{ option }}</option>
          <option value="Other">Other</option>
        </select>
      </div>
    </div>

    <div class="form__row" v-if="props.showRole && (!position || props.positionRole == position)">
      <div class="form__field">
        <label for="role">
          {{props.role}}
          <span class="optional">(optional)</span>  
        </label>
        <input type="text" name="role" id="role" v-model="form_submission.role">
        <div class="error" v-if="errors.role">{{ errors.role }}</div>
      </div>
    </div>  

    <div class="form__row" v-if="props.showCountry && (!position || props.positionCountry == position)">
      <div class="form__field">
        <label for="country">
          {{props.country}}
          <span class="optional">(optional)</span>  
        </label>
        <input type="text" name="country" id="country" v-model="form_submission.country">
        <div class="error" v-if="errors.country">{{ errors.country }}</div>
      </div>
    </div>      

    <div class="form__row" v-if="props.showRegion && (!position || props.positionRegion == position)">
      <div class="form__field">
        <label for="region">
          {{props.region}}
          <span class="optional">(optional)</span>  
        </label>
        <select name="region" id="region" v-model="form_submission.region">
          <option value="">Select</option>
          <option v-for="option in regionOptions" :key="option" :value="option">{{ option }}</option>
        </select>
        <div class="error" v-if="errors.region">{{ errors.region }}</div>
      </div>
    </div>                          

    <div class="form__row" v-if="props.showCustomerRelationship && (!position || props.positionCustomerRelationship == position)">
      <div class="form__field">
        <label for="customer_relationship">
          {{props.customerRelationship}}
          <span class="optional">(optional)</span>  
        </label>
        <select name="customer_relationship" id="customer_relationship" v-model="form_submission.customer_relationship">
          <option value="">Select</option>
          <option value="I am a customer">I am a customer</option>
          <option value="I am a former customer">I am a former customer</option>
          <option value="I have not yet worked with Diaceutics">I have not yet worked with Diaceutics</option>
        </select>
      </div>
    </div>              

    <div class="form__row" v-if="props.showMessage && (!position || props.positionMessage == position)">
      <div class="form__field">
        <label for="message">{{props.message}}</label>
        <textarea name="message" id="message" cols="30" rows="10" v-model="form_submission.message"></textarea>
        <div class="error" v-if="errors.message">{{ errors.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>


const INDUSTRY_OPTIONS = [
  'Pharmaceutical',
  'Healthcare',
  'Health Insurance',
  'Laboratory',
  'Diagnostic (Dx)',
  'Biotechnology',
  'Medical Device',
  'Clinical Research Organisation (CRO)',
  'Data Solutions',
  'Health System / Provider',
  'Patient Engagement Solutions',
  'Data Provider',
  'Patient Organisation',
  'Service Provider'
]

const REGION_OPTIONS = [
  'North America',
  'EU',
  'APAC',
  'LATAM',
  'Africa',
  'Middle East'
]


export default {
  props: {
    value: { type: Object, required: true },
    errors: { type: Object, required: true },
    props: { type: Object, required: true},
    position: { type: String, default: null },
  },

   data() {
    return {
      industryOptions: INDUSTRY_OPTIONS,    
      regionOptions: REGION_OPTIONS,     
      form_submission: this.value,
    }
  },
  
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
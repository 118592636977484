<template>
  <section id="apply" class="m-single-position__apply">
    <div class="inner-wrapper">
      <form action="" class="diaceutics-form" @submit="onSubmit">
        <div class="diaceutics-form__upper">
          <div class="diaceutics-form__left">
            <h2>To apply, simply fill out the form opposite and we'll get back to you</h2>
          </div>
          <div class="diaceutics-form__right">
            <div class="form__row">
              <div class="form__field">
                <label for="name">Name</label>
                <input type="text" name="name" id="name" required v-model="form_submission.name">
                <div class="error" v-if="errors.name">{{ errors.name }}</div>
              </div>
            </div>

            <div class="form__row">
              <div class="form__field">
                <label for="telephone">Telephone</label>
                <input type="text" name="telephone" id="telephone" required v-model="form_submission.telephone">
                <div class="error" v-if="errors.telephone">{{ errors.telephone }}</div>
              </div>
            </div>

            <div class="form__row">
              <div class="form__field">
                <label for="email">Email</label>
                <input type="email" name="email" id="email" required v-model="form_submission.email">
                <div class="error" v-if="errors.email">{{ errors.email }}</div>
              </div>
            </div>            

            <div class="form__row">
              <div class="form__field">
                <label for="resume">Resumé</label>
                <div class="file-field">
                  <input type="file" ref="file" class="file-input" @change="handleFileUpload()" name="resume" id="resume">
                  <!-- <span class="file-name" v-if="form_submission.file">{{form_submission.file.name}}</span> -->
                </div>
              </div>
            </div>

            <div class="form__row">
              <div class="form__field">
                <label for="message">Your Message</label>
                <textarea name="message" id="message" cols="30" rows="10" required v-model="form_submission.message"></textarea>
                <div class="error" v-if="errors.message">{{ errors.message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="diaceutics-form__lower">
          <transition name="success">
            <span class="success-message" v-if="success">Thanks, your application has been received</span>
          </transition>          
          <button type="submit" class="button button--large button--form-submit">Send</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Api from "../utils/api";

export default {
  props: ["title", "jobId", "campaign"],
  
  data: function() {
    return {
      form_submission: {
        name: '',
        email: '',
        file: '',
        message: '',
      },
      errors: {},
      success: false,
      isSending: false,
    }
  },

  methods: {

    handleFileUpload(){
      this.form_submission.file = this.$refs.file.files[0];
    },

	  clearForm() {
			for (let field in this.form_submission) {
				this.form_submission[field] = ''
      }
      this.errors = {}
		},

		onSubmit(evt) {
			evt.preventDefault();

      this.isSending = true;
      
      let formData = new FormData();
      formData.set("form_submission[name]", this.form_submission.name)
      formData.set("form_submission[telephone]", this.form_submission.telephone)
      formData.set("form_submission[email]", this.form_submission.email)
      formData.set("form_submission[message]", this.form_submission.message)
      formData.set("form_submission[form]", "job_application")
      formData.set("form_submission[job_id]", this.jobId )
      formData.set("form_submission[campaign]", this.campaign )
      formData.append("form_submission[file]", this.form_submission.file )

      Api.post('/form_submissions', 
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) => {
          this.clearForm();
          this.isSending = false;
          this.success = true;

          // Google Adwords Conversion
          // Event snippet for Contact Us Form Sent conversion page 
          // let output = gtag('event', 'conversion', {'send_to': 'AW-964383798/YQlaCO72vFcQtqjtywM'});          

          setTimeout(() => {
            this.success = false; // Fade out success message after a short white
          }, 4000);
        }).catch((e) => {
          this.isSending = false;
          console.log(e.response)
          if (e.response && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Sorry, something went wrong, please try again.")
          }
        });
	  	}
	}    
}
</script>

<style lang="scss" scoped>
  .success-message {
    padding: 22px 28px 28px 20px;
  }

  .success-enter-active, .success-leave-active {
    transition: opacity .5s;
  }
  
  .success-enter, .success-leave-to {
    opacity: 0;
  }

  .error {
    padding-top: 5px;
    font-size: 90%;
    color: #E9260F;
  }
</style>
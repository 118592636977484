<template>
  <div>
    <div :class="['modal', {'is-visible': visible}]"></div>
    <div :class="['lead-capture-form', {'is-visible': visible}]">

      <div class="lead-capture-form__container">
        <div class="inner-wrapper">
          <form action="register.html" class="diaceutics-form" @submit="onSubmit">
            <div class="diaceutics-form__upper">
              <div class="diaceutics-form__left">
                <h2>{{title || "Complete this form to access the page" }}</h2>
                <div v-html="intro" v-if="intro" class="wysiwyg" />                
              </div>
              <div class="diaceutics-form__right">
                <div class="success" v-if="success">
                  <h3 class="thank-you">Thank you</h3>
                  <div v-html="body" v-if="body" />
                  <span class="success-text" v-else>Thanks, please check your email for link.</span>
                </div>

                <div class="form" v-if="!success"> 
                  <contact-form-fields :props="props" v-model="form_submission" :errors="errors" position="right" />
                </div>

              </div>
            </div>
            <div class="diaceutics-form__lower" v-if="!success">
              <button type="submit" class="button button--large button--form-submit button--form-submit--filled--blue">{{ button }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../utils/api";
import { EventBus } from '../packs/event-bus';

export default {
  props: {
    active: { type: Boolean, default: true },
    campaign: { type: String, default: ""},
    pageModuleId: { type: Number },
    title: { },
    name: { default: "Name" },
    telephone: { default: "Telephone" },
    email: { default: "Email" },
    organisation: { default: "Organization"},
    body: {},
    intro: {},
    button: { default: "Submit"},

    showName: { type: Boolean },
    showTelephone: { type: Boolean },
    showEmail: { type: Boolean },
    showOrganisation: { type: Boolean},

    positionName: { type: String, default: "right" },
    positionTelephone: { type: String, default: "right"  },
    positionEmail: { type: String, default: "right"  },
    positionOrganisation: { type: String, default: "right" },
  },
  
  data: function() {
    return {
      visible: false,
      form_submission: {
        name: '',
        telephone: '',
        email: '',
        organisation: '',
      },
      errors: {},
      success: false,
      isSending: false,
    }
  },

  computed: {
    props() {
      return this.$props;
    }
  },

  mounted() {
    console.log('mounted', this.active)
    if (this.active) {
      setTimeout(() => {
        this.visible = true;
      }, 500)
    }
  },
  methods: {
	  clearForm() {
			for (let field in this.form_submission) {
				this.form_submission[field] = ''
      }
      this.errors = {}
		},
		onSubmit(evt) {
			evt.preventDefault();

      this.isSending = true;
      
      Api.post('/form_submissions', { 
        form_submission: {
          ...this.form_submission, 
          campaign: this.campaign,
          page_module_id: this.pageModuleId,
          form: "lead_capture" 
        } 
      })
        .then((response) => {
          this.clearForm();
          this.isSending = false;
          this.success = true;
        }).catch((e) => {
          this.isSending = false;
          if (e.response && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Sorry, something went wrong, please try again.")
          }
        });
	  	}
	}    
}
</script>

<style lang="scss" scoped>
  .modal {
		position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    opacity: 0;
    pointer-events: none;
    background: #16152E;
    transition: opacity .5s ease-out;
    
    &.is-visible {
      opacity: 0.7;
      pointer-events: all;
    }
	}

  .lead-capture-form {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 60px;
    max-height: 85vh;
    height: auto;
    
    @media screen and (max-width: 768px) {
      height: 100%;
    }

    z-index: 5;
    transform: translateY(100%);
    transition: transform .5s;

    &__container {
      // position: relative;
      background-color: #fff;
      border-radius: 30px 30px 0 0;
      padding: 80px 0 28px;
      height: 100%;
      max-height: 100%;
      
      .inner-wrapper {
        height: 100%;
        overflow: auto;

        @media screen and (min-width: 768px) {
          padding: 0 40px;
        }
      }
    }

    &.is-visible {
      transform: translateY(0);

      .report-download-form__close {
        opacity: 1;
      }
    }
  }

  .success {
    .thank-you {
      margin-bottom: 28px;
    }

    .success-text {
      font-size: 0.875rem;
      line-height: 1.78;
      margin-bottom: 28px;
    }
  }

  .success-enter-active, .success-leave-active {
    transition: opacity .5s;
  }
  
  .success-enter, .success-leave-to {
    opacity: 0;
  }

  .error {
    padding-top: 5px;
    font-size: 90%;
    color: #E9260F;
  }
</style>
<template>
  <div :class="['report-download-form', {'is-visible': visible}]">
    <button type="button" class="report-download-form__close" @click="hideForm">
      <i class="icon icon--close--pink"></i>
    </button>

    <div class="report-download-form__container">
      <div class="inner-wrapper">
        <form action="register.html" class="diaceutics-form" @submit="onSubmit">
          <div class="diaceutics-form__upper">
            <div class="diaceutics-form__left">
              <h2>Complete this form to download "{{article.title}}"</h2>
            </div>
            <div class="diaceutics-form__right">
              <div class="success" v-if="success">
                <h3 class="thank-you">Thank you</h3>
                <p class="success-text">We've sent a link to your e-mail address to download the report, you can also use the download link below.</p>
                <a :href="article.attachment_url" target="_blank" rel="noopener nofollower" class="button button--filled--blue">Download Report</a>
              </div>

              <div class="form" v-if="!success"> 
                <div class="form__row">
                  <div class="form__field">
                    <label for="name">Name</label>
                    <input type="text" name="name" id="name" required v-model="form_submission.name">
                    <div class="error" v-if="errors.name">{{ errors.name }}</div>
                  </div>
                </div>

                <div class="form__row">
                  <div class="form__field">
                    <label for="email">Email</label>
                    <input type="email" name="email" id="email" required v-model="form_submission.email">
                    <div class="error" v-if="errors.email">{{ errors.email }}</div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div class="diaceutics-form__lower" v-if="!success">
            <button type="submit" class="button button--large button--form-submit button--form-submit--filled--blue">Download Report</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../utils/api";
import { EventBus } from '../packs/event-bus';
export default {
  props: ["article", "campaign"],
  
  data: function() {
    return {
      visible: false,
      form_submission: {
        name: '',
        email: '',
      },
      errors: {},
      success: false,
      isSending: false,
    }
  },
  mounted() {
    EventBus.$on('showReportDownloadForm', () => {
      this.visible = true;
    });
  },
  methods: {
    hideForm() {
      this.visible = false;
      EventBus.$emit('toggleModal', {visibility: 'hide'});
      EventBus.$emit('bodyNoScroll', {scroll: 'true'});
    },
	  clearForm() {
			for (let field in this.form_submission) {
				this.form_submission[field] = ''
      }
      this.errors = {}
		},
		onSubmit(evt) {
			evt.preventDefault();

      this.isSending = true;
      
      Api.post('/form_submissions', { 
        form_submission: {
          ...this.form_submission, 
          campaign: this.campaign,
          form: "download", 
          article_id: this.article.id 
        } 
      })
        .then((response) => {
          this.clearForm();
          this.isSending = false;
          this.success = true;
        }).catch((e) => {
          this.isSending = false;
          if (e.response && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Sorry, something went wrong, please try again.")
          }
        });
	  	}
	}    
}
</script>

<style lang="scss" scoped>
  .report-download-form {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 98vh;
    padding-top: 60px;
    max-height: 600px;

    @media screen and (max-width: 575px) {
      max-height: 700px;
    }

    z-index: 5;
    transform: translateY(100%);
    transition: transform .3s;

    &__container {
      position: relative;
      background-color: #fff;
      border-radius: 30px 30px 0 0;
      padding: 80px 0 28px;
      height: 100%;
      overflow: scroll;
      /* this will hide the scrollbar in mozilla based browsers */
      overflow: -moz-scrollbars-none;
      /* this will hide the scrollbar in internet explorers */
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
      z-index: 5;
      opacity: 0;
      transition: opacity .3s;

      @media screen and (max-width: 575px) {
        right: 10px;
      }

    }

    &.is-visible {
      transform: translateY(0);

      .report-download-form__close {
        opacity: 1;
      }
    }

    .diaceutics-form__upper {
      padding-bottom: 40px;
    }
  }

  .success {
    .thank-you {
      margin-bottom: 28px;
    }

    .success-text {
      font-size: 0.875rem;
      line-height: 1.78;
      margin-bottom: 28px;
    }
  }

  .success-enter-active, .success-leave-active {
    transition: opacity .5s;
  }
  
  .success-enter, .success-leave-to {
    opacity: 0;
  }

  .error {
    padding-top: 5px;
    font-size: 90%;
    color: #E9260F;
  }
</style>
import axios from "axios";

const token = document
  .getElementsByName("csrf-token")[0]
  .getAttribute("content");

const Api = axios.create({
  baseURL: `/admin/api`,
  headers: {
    "X-CSRF-Token": token,
    Accept: "application/json"
  }
});

export default Api;
<template>
  <div>
    <!-- Display & Actions -->
    <div class="dropzone-box">
      <input type="hidden" :value="currentFile ? currentFile.id : object.id" :name="object.field" class="form-control hidden" />              
      <div v-if="file && file.url && !currentFile" class="image">
        <img :src="file.url" :width="width" :height="height" />
      </div>
      <div v-if="currentFile && currentFile.original" class="image">
        <img :src="currentFile.original" :width="width" :height="height" />
      </div>
      <div class="actions">
        <div @click="visible=true" class="btn btn__diaceutics-brand-bank">
          <IconBrandBank style="margin-right: 11px" />
          Choose from Diaceutics Brand Bank
        </div>
        <div @click="$refs.uploadFile.click()" class="btn btn__upload-computer" v-if="!disableUpload">
          <input type="file" id="uploadFile" ref="uploadFile"  v-on:change="handleUploadFromComputer()" accept="image/*">
          <IconUpload style="margin-right: 11px" />
          Upload from Computer
        </div>
        <a @click="removeFile()" class="btn btn__remove-file">
          <i class="fa fa-trash"></i>
        </a>
      </div>
    </div>

    <!-- Background -->
    <Transition name="fade">
      <div
        v-if="visible"
        class="background"
        @click.self="visible=false"
      >
      </div>
    </Transition>

    <!-- Popup -->
    <div :class="['files-selector', {'is-visible': visible}]">
      <div class="files-selector__container">
        <div class="inner-wrapper" style="width:100%;height:100%">
          <iframe :src="url" width="100%" height="100%" v-scroll-lock="visible"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBrandBank from '../assets/images/icons/icon--brand-bank'
import IconUpload from '../assets/images/icons/icon--upload'
import AttoAdminApi from '../utils/atto_admin_api'
import axios from "axios";

export default {
  props: {
    file: {
      type: Object,
      default: {},
    },
    object: {
      type: Object,
      default: {},
    },
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "auto",
    },
    type: {
      type: String,
      default: "icon",
    },
    url : {
      type: String,
      required: true,
    },
    disableUpload : {
      type: Boolean,
      default: false,
    }, 
  },

  components: {
    IconBrandBank,
    IconUpload,
  },

  data: function() {
    return {
      visible: false,
      currentFile: null,
    }
  },

  mounted() {
    const vm = this
    window.addEventListener('message', function (e) {
      if (e.data.event === 'file-upload' && vm.visible) {
        vm.handleUploadFromBrandBank(e.data.file)
        vm.visible = false
      }
    });
  },

  methods: {
    handleUploadFromBrandBank(file) {
      let formData = new FormData();
      formData.append('file', new Blob([file.data]), file.name);
      this.uploadFile(formData)
    },
    
    handleUploadFromComputer() {
      const file = this.$refs.uploadFile.files[0];
        if (!file)
          return

      let formData = new FormData();
      formData.append('file', file);
      this.uploadFile(formData)
    },

    uploadFile(formData){
      // let loader = this.$loading.show()

      let vm = this
      AttoAdminApi.post('/heros',
        formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(response => {
          vm.currentFile = response.data
          window.onDropzoneComplete(response.data)
          // loader.hide()
        })
        .catch(error => {
          console.log("error", error)
          // loader.hide()
        });
      },   
    removeFile() {
      this.currentFile = {}
    }
  },


}
</script>

<style lang="scss" scoped>
  .dropzone-box {
    margin-bottom: 10px;
  }

  input[type="file"]{
    position: absolute;
    top: -9000px;
    left: -9000px;
  }

  .background {
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,0.5);
  }

  .files-selector {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 85vh;
    // padding-top: 60px;
    z-index: 2000;
    transform: translateY(100%);
    transition: transform .3s;

    &__container {
      position: relative;
      background-color: #f7f8f9;
      border-radius: 30px 30px 0 0;
      padding: 20px;
      height: 100%;
      overflow: scroll;
      /* this will hide the scrollbar in mozilla based browsers */
      overflow: -moz-scrollbars-none;
      /* this will hide the scrollbar in internet explorers */
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
      z-index: 5;
      opacity: 0;
      transition: opacity .3s;

      @media screen and (max-width: 575px) {
        right: 10px;
      }

    }

    &.is-visible {
      transform: translateY(0);

      .files-selector__close {
        opacity: 1;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.4s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }


  .btn {
    cursor: pointer;
    border-radius: 6px;
    margin: 12px;
    padding: 12px;
    display: inline-block;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    min-height: 48px;
    min-width: 48px;

    &__diaceutics-brand-bank {
      background-color: #007BFF;
      color: #ffffff;
    }

    &__upload-computer {
      background-color: #ffffff;
      color: #007BFF;
      border: 1px solid #007BFF
    }

    &__remove-file {
      background-color: #ffffff;
      color: #000;
      border: 1px solid #999;

      // &:hover {
      //   background-color: #f7f8f9;
      //   border: 1px solid #999;
      // }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100px;
  }

  .image {
    text-align: center;
    padding: 20px;
    padding-bottom: 0px;
    font-size: 30px;
  }

</style>
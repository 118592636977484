<template>
  <section class="s-team-members">
    <div class="outer-wrapper">

      <div class="m-our-team__filters" v-if="showFilters">
        <div class="inner-wrapper">
          <h2 class="title">Filters</h2>

          <ul class="m-our-team__filters__list">
            <li v-for="team in teamOptions" :key="team.id">
              <a @click="activeTeam=team" :class="`filter ${activeTeam.id == team.id ? 'active' : ''}`">{{team.name}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="inner-wrapper" :style="!showFilters ? 'padding-top:120px' : ''">
        <div class="m-our-team__grid">
          <div class="m-our-team__member" v-for="teamMember in teamMembers" :key="teamMember.id" @click="selectTeamMember(teamMember)">
            <div class="m-our-team__member__image">
              <img :src="teamMember.image" alt="" v-if="teamMember.image" />
            </div>
            <div class="m-our-team__member__details">
              <h5 class="name">{{teamMember.name }}</h5>
              <p class="title">{{teamMember.role}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="['m-our-team__modal', {'is-visible': modalVisible}]" @click="dismissProfile"></div>

    <TeamMemberProfile 
      :activeTeamMember="activeTeamMember" 
      :visible="profileVisible" 
      v-on:dismissProfile="dismissProfile"
    />
  </section>
</template>

<script>
import Api from "../utils/api";
import TeamMemberProfile from './team-member-profile';
import _ from 'lodash';

export default {
  props: ["teams", "teamMember"],
  components: {
    TeamMemberProfile
  },
  data() {
    return {
      showFilters: true,
      teamMembers: [],
      activeTeam: {id: null, name: "Show All"},
      activeTeamMember: {},
      hasMore: false,
      loading: false,
      modalVisible: false,
      profileVisible: false,
    }
  },

  computed: {
    teamOptions() {
      let teamsSorted = _.sortBy(this.teams, 'name')
      return [{id: null, name: "Show All"}, ...teamsSorted]
    },
  },

  mounted() {
    this.fetch();
    if (this.teamMember) {
      this.selectTeamMember(this.teamMember)
    }
  },  

  watch: {
    activeTeam() {
      this.fetch()
    }
  },

  methods: {
    fetch() {
      this.loading = true
      this.hasMore = false
      Api.get('/team_members', { params: { 
        team_id: this.activeTeam.id
        }})
        .then((response) => {
          this.teamMembers = response.data.team_members
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    selectTeamMember(teamMember) {
      this.activeTeamMember = teamMember;
      window.history.replaceState({}, null, `/team_members/${teamMember.slug}`)
      this.modalVisible = true;
      this.profileVisible = true;
    },

    dismissProfile() {
      this.modalVisible = false;
      this.profileVisible = false;
      window.history.replaceState({}, null, "/team_members")
      setTimeout(() => {
        this.activeTeamMember = {};  

      }, 500);
    }
  }
}
</script>
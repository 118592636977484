<template>
  <div class="author" v-if="article.author">
    <img :src="article.author.image" class="author-image" />
    <div class="details">
      <p class="name">{{ article.author.name }}</p>
      <p class="more" v-if="article.authorCount > 1">&amp; {{ article.authorCount}} more</p>
    </div>
  </div>

</template>

<script>
export default {
  props: ["article"],
}
</script>

<style lang="scss" scoped>
  .author {
    position: absolute;
    top: 28px;
    right: 28px;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 8px;
    background: rgba(#fff, 0.5);
    border-radius: 30px;

    @media screen and (max-width: 479px) {
      top: 12px;
      right: 12px;
    }
  }

  .author-image {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 12px;
  }

  .details {
    .name,
    .more {
      font-size: 0.8125rem;
      font-family: 'Inter', Arial, Helvetica, sans-serif;
      font-style:  normal;
      font-weight: 600;
      
      line-height: 1.2;
    }
    
    .more {
      color: #CB93ED;
    }
  }
</style>
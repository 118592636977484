<template>
  <div class="m-card-carousel">
    <flickity ref="flickity" :options="flickityOptions" :key="">
      <div class="m-card-carousel-cell" v-for="card in data" :key="card.id">
        <a class="card-link" :href="card.url">
          <div class="m-card-carousel__container">
            <div class="m-card-carousel__image" v-if="card.image">
              <img :src="card.image['2x']" alt="" />
            </div>

            <div class="m-card-carousel__status">
              {{ card.status }}
            </div>

            <div class="m-card-carousel__title">
              <h4 class="title">{{ card.title }}</h4>
            </div>

            <div class="m-card-carousel__content" v-html="card.content" />

            <div class="m-card-carousel__details">
              <div class="m-card-carousel__details__date">
                <i class="icon icon--clock--pink" v-if="card.date" />
                {{ card.date ? `Closing ${card.date}` : `` }}
              </div>
              <div class="m-card-carousel__details__location">
                <i class="icon icon--location" v-if="card.location" />
                {{ card.location }}
              </div>
            </div>
          </div>
        </a>
      </div>
    </flickity>
    <template v-if="data.length > 1">
      <button
        @click="previous()"
        class="m-card-carousel__button m-testimonials__button--prev"
      >
        <i class="icon icon--card-carousel--prev"></i>
      </button>

      <button
        @click="next()"
        class="m-card-carousel__button m-testimonials__button--next"
      >
        <i class="icon icon--card-carousel--next"></i>
      </button>
    </template>
  </div>
</template>

<script>
import Flickity from "vue-flickity";

export default {
  props: {
    data: Array,
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
  },
  mounted() {
    // This is to size all cards to the same size
    this.$nextTick(() => {
      document.querySelectorAll(".m-card-carousel-cell").forEach(e => e.style.height = '100%')
    })
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        groupCells: true,
        imagesLoaded: true,
        percentPosition: false,
        // adaptiveHeight: true,
        // setGallerySize: false
      },
    };
  },


  computed: {
    filteredCards() {
      return this.cards.filter((test) => test);
    },
  },
};
</script>

<style lang="scss">
.m-card-carousel {
  .flickity-viewport {
    transition: height 0.2s;
    height: 100%;
    // padding-top: 36.25%;
  }

  .flickity-slider{
    // margin-top:-36.25%;
  }
}
</style>

<template>
  <div :class="`partnerships__grid grid-size-${partnerships.length}`" v-scroll-lock="profileVisible">
    <div v-for="(partnership, index) in partnerships" :key="partnership.id" :class="`partnerships__grid__container item-${ index + 1 }`">
      <div v-if="partnership.image" class="partnerships__grid__container__item">
        <img :src="partnership.image" :alt="partnership.name" class="logo" @click="selectPartnership(partnership)" />
      </div>
    </div>

    <div :class="['m-our-team__modal', {'is-visible': modalVisible}]" @click="dismissProfile"></div>

    <PartnershipProfile 
      :activePartnership="activePartnership" 
      :visible="profileVisible" 
      v-on:dismissProfile="dismissProfile"
    />

  </div>
</template>

<script>
import PartnershipProfile from './partnership-profile';
import _ from 'lodash';

export default {
  props: ["partnerships"],
  components: {
    PartnershipProfile
  },
  data() {
    return {
      activePartnership: {},
      modalVisible: false,
      profileVisible: false,
    }
  },
  methods: {
    selectPartnership(partnership) {
      this.activePartnership = partnership;
      this.modalVisible = true;
      this.profileVisible = true;
    },

    dismissProfile() {
      this.modalVisible = false;
      this.profileVisible = false;
      setTimeout(() => {
        this.activePartnership = {};  

      }, 500);
    }
  }
}
</script>
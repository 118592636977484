<template>
  <section :id="pageModuleId" class="s-contact-form">
    <div class="inner-wrapper">
      <form action="register.html" class="diaceutics-form" :class="layout" @submit="onSubmit">
          
        <!-- Two Column Layout -->
        <template v-if="layout == 'two_columns'">
          <h2>{{title}}</h2>
          <div v-html="intro" v-if="intro" class="wysiwyg" />
          
          <template v-if="success">
            <transition name="success">
              <div v-html="body" v-if="body" />
              <span class="success-message" v-else>Thanks, your message has been received</span>
            </transition>
          </template>

          <template v-else>
            <div class="diaceutics-form__upper">
              <div class="diaceutics-form__left">
                <contact-form-fields :props="props" v-model="form_submission" position="left" :errors="errors" />
              </div>
              <div class="diaceutics-form__right">
                <contact-form-fields :props="props" v-model="form_submission" position="right" :errors="errors" />
              </div>
            </div>
          </template>
        </template>

        <!-- Default layout -->
        <template v-else>
          <div class="diaceutics-form__upper">
            <div class="diaceutics-form__left">
              <h2>{{title}}</h2>
            </div>
            <div class="diaceutics-form__right">
              
              <template v-if="success">
                <transition name="success">
                  <div v-html="body" v-if="body" />
                  <span class="success-message" v-else>Thanks, your message has been received</span>
                </transition>
              </template>
            
              <template v-else>
                <div v-html="intro" v-if="intro" class="wysiwyg" style="margin-bottom:28px;" />
                <contact-form-fields :props="props" v-model="form_submission" :errors="errors" />
              </template>
            </div>
          </div>
        </template>          

        <div class="diaceutics-form__lower" v-if="!success">
          <button type="submit" class="button button--large button--form-submit">{{button}}</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Api from "../utils/api";

export default {
  props: {
    layout: { type: String, default: "default" },
    campaign: { type: String, default: ""},
    pageModuleId: { type: Number },
    title: { },
    name: { default: "Name" },
    telephone: { default: "Telephone" },
    email: { default: "Email" },
    message: { default: "Your Message" },
    organisation: { default: "Organization"},
    industry: { default: "Industry"},
    role: { default: "Role / Job Title"},
    country: { default: "Country"},
    region: { default: "Region"},
    customerRelationship: { default: "What is your relationship to Diaceutics?" },
    body: {},
    intro: {},
    button: { default: "Send"},
    redirectUrl: {},
    actionLabel: { default: "What would you like to do?" },

    showName: { type: Boolean },
    showTelephone: { type: Boolean },
    showEmail: { type: Boolean },
    showMessage: { type: Boolean },
    showOrganisation: { type: Boolean},
    showIndustry: { type: Boolean},
    showRole: { type: Boolean},
    showCountry: { type: Boolean},
    showRegion: { type: Boolean},
    showCustomerRelationship: { type: Boolean},
    showAction: { type: Boolean },

    positionName: { type: String, default: "right" },
    positionTelephone: { type: String, default: "right"  },
    positionEmail: { type: String, default: "right"  },
    positionMessage: { type: String, default: "right"  },
    positionOrganisation: { type: String, default: "right" },
    positionIndustry: { type: String, default: "right" },
    positionRole: { type: String, default: "right" },
    positionCountry: { type: String, default: "right" },
    positionRegion: { type: String, default: "right" },
    positionCustomerRelationship: { type: String, default: "right" },
    positionAction: { type: String, default: "right" },

  },
  
  data: function() {
    return {
      form_submission: {
        action: '',
        name: '',
        telephone: '',
        email: '',
        organisation: '',
        industry: '', // snake case so it directly maps to rails fields
        role: '',
        country: '',
        region: '',
        customer_relationship: '',
        message: '',
      },
      errors: {},
      success: false,
      isSending: false,
    }
  },

  computed: {
    props() {
      return this.$props;
    }
  },

  methods: {

	  clearForm() {
			for (let field in this.form_submission) {
				this.form_submission[field] = ''
      }
      this.errors = {}
		},

		onSubmit(evt) {
			evt.preventDefault();

      this.isSending = true;
      
      Api.post('/form_submissions', { 
        form_submission: 
          {
            ...this.form_submission, 
            campaign: this.campaign,
            page_module_id: this.pageModuleId,
            form: "contact_us" 
          } 
        })
        .then((response) => {
          this.clearForm();
          this.isSending = false;
          this.success = true;

          // Google Adwords Conversion
          // Event snippet for Contact Us Form Sent conversion page 
          // let output = gtag('event', 'conversion', {'send_to': 'AW-964383798/YQlaCO72vFcQtqjtywM'}); 

          if(this.redirectUrl) {
            window.location = this.redirectUrl
          }         

          // setTimeout(() => {
          //   this.success = false; // Fade out success message after a short white
          // }, 4000);
        }).catch((e) => {
          this.isSending = false;
          console.log(e.response)
          if (e.response && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Sorry, something went wrong, please try again.")
          }
        });
	  	}
	}    
}
</script>

<style lang="scss" scoped>
  .success-message {
    padding: 22px 28px 28px 20px;
  }

  .success-enter-active, .success-leave-active {
    transition: opacity .5s;
  }
  
  .success-enter, .success-leave-to {
    opacity: 0;
  }

  .error {
    padding-top: 5px;
    font-size: 90%;
    color: #E9260F;
  }
</style>
<template>
    <share-network
      network="twitter"
      :url="url"
      :title="title"
      twitter-user="diaceutics"
    >
    <span class="icon icon--social--x" />
  </share-network>
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
    title: { type: String, required: true }
  }
}
</script>
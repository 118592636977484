<template>
  <div :class="['container', {'is-visible': visible }, {'is-sticky': sticky }]">
    <div class="prompt">
      <div class="prompt__left">
        <p class="prompt__title">{{ article.title }}, <span class="month-and-year">{{ article.month }} {{ article.year }}</span></p>
        <p class="mobile-prompt-title">{{ truncatedTitle }} <span class="month-and-year">{{ article.month }} {{ article.year }}</span></p>
      </div>

      <div class="prompt__right">
        <button v-if="article.report_type == 'onsite_leadcapture'" type="button" class="button button--filled--blue" @click="triggerForm">Download <span class="report">Report</span></button>
        <button v-if="article.report_type == 'external_link'" type="button" class="button button--filled--blue" @click="triggerExternalLink">View <span class="report">Report</span></button>
        <button v-if="article.report_type == 'onsite_noleadcapture'" type="button" class="button button--filled--blue" @click="triggerDownload">Download <span class="report">Report</span></button>        
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../packs/event-bus';
export default {
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      sticky: false,
    }
  },
  computed: {
    truncatedTitle() {
      let string;
      if(this.article.title.length > 30) {
        string = this.article.title.slice(0, 30) + '...';
      }
      return string;
    }
  },
  methods: {
    triggerForm() {
      this.visible = false;
      EventBus.$emit('showReportDownloadForm');
      EventBus.$emit('toggleModal', {visibility: 'show'});
      EventBus.$emit('bodyNoScroll', {scroll: 'false'});
    },
    triggerExternalLink() {
      window.open(this.article.report_url, "_blank");    
    },
    triggerDownload() {
      window.open(this.article.attachment_url, "_blank");    
    }
  },
  mounted() {
    EventBus.$on('toggleReportPrompt', (payload) => {
      if(payload.visibility == 'hide') {
        this.visible = false;
      } else if(payload.visibility == 'show') {
        this.visible = true;
      }
    })

    EventBus.$on('toggleReportPromptSticky', (payload) => {
      this.sticky = payload;
    })    
  }
}
</script>

<style lang="scss" scoped>
  .container {
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    z-index: 5;
    transition: transform .6s cubic-bezier(.25,1.34,.65,1.01);

    &.is-visible {
      transform: translateY(-120%);
    }

    &.is-sticky {
      transform: translateY(-120%);
      position: static;      
      top: auto;
      left: auto;
      right: auto;
      width: 100%;
      margin-bottom: -100px;      
      height: 100px;
    }

    @media screen and (max-width: 479px) {
      height: auto;
    }
  }

  .prompt {
    height: 100%;
    background: #E7E7E7;
    background-image: url('../../assets/images/backgrounds/report-download-prompt-bg.png');
    background-size: cover;
    background-position: center;
    border-radius: 21px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;

    @media screen and (max-width: 768px) {
      padding: 0 28px;
    }

    @media screen and (max-width: 479px) {
      padding: 18px;
    }
  }

  .prompt__left {
    padding-right: 14px;
  }

  .prompt__title {
    font-family: 'Inter-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #19193B;
    letter-spacing: 0;
    line-height: 32px;

    .month-and-year {
      display: inline-block;
      font-family: 'Inter', Arial, Helvetica, sans-serif;
      font-style:  normal;
      font-weight: 500;
      
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .mobile-prompt-title {
    display: none;
    font-family: 'Inter-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #19193B;
    line-height: 1.2;

    .month-and-year {
      display: block;
      font-family: 'Inter', Arial, Helvetica, sans-serif;
      font-style:  normal;
      font-weight: 500;
      
    }

    @media screen and (max-width: 768px) {
      display: block;
    }

    @media screen and (max-width: 479px) {
      font-size: 12px;
      line-height: 1.2;
    }
  }

  .button {
    @media screen and (max-width: 768px) {
      .report {
        display: none;
      }
    }
  }
</style>
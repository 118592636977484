<template>
  <section id="id" class="s-header-ktn">
    <div class="m-hero m-hero--ktn">
      <div class="m-hero__container">
        <div class="inner-wrapper">
          <div class="m-hero__content">
            <div id="header-ktn-title" class="m-hero__title">
              <h1 class="title">
              {{ title }}
              </h1>
              <div class="controls">
                <div id="button-back-to-top" class="button button--hollow--pink button--skip" @click="backToTop">
                  Back To Top
                </div>
                <div id="button-skip" class="button button--hollow--pink button--skip" @click="skipAnimation">
                  Skip
                </div>
              </div>
            </div>
            <div id="header-ktn-copy" class="m-hero__copy wysiwyg" v-html="content" >
            </div>
            <div id="people" class="people">
              <ktn-people></ktn-people>
            </div>
          </div>

          <div class="sections" id="sections">
            <div class="section" :id="`section-${i + 1}`" v-for="(section, i) in sections" :key="i">
              <div class="section__content">
                <div class="section__number" v-if="i < sections.length-1">{{ i + 1}} </div>
                <div class="section__title">{{ section.title }}</div>
                <div class="section__copy wysiwyg" v-html="section.content"></div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>

</template>

<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Observer from "gsap/Observer";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Observer);
gsap.registerPlugin(ScrollToPlugin);

export default {
  props: ['id','title','content', 'sections'],

  data() {
    return {
      stage: 0,
      currentIndex: null,
      animating: false,
      observer: null,
      direction: null,
      deltaY: 0,
      skip: false,
    };
  },

  computed: {
    speed() {
      let params = new URLSearchParams(window.location.search);
      return this.skip ? 0.1 : params.get('speed') || 1.2;
    }
  },

  watch: {
    animating(value) {
      console.log('animating', value)
    },
    stage(value) {
      console.log('stage', value)
    },
    skip(value) {
      console.log('skip', value)
    }
  },

  mounted() {
    // Ensure the page always starts at the top, even on refresh
    window.scrollTo(0, 0)
    window.onbeforeunload = () => { window.scrollTo(0,0) }
    document.querySelector("body").classList.add("is-animating")
    this.observer = Observer.create({
      type: "wheel, touch, pointer",
      wheelSpeed: -1,
      onUp: (observer) => !this.animating && Math.abs(observer.deltaY) > (this.deltaY+10) && this.next(observer),
      onDown: (observer) => !this.animating && Math.abs(observer.deltaY) > (this.deltaY+10) && this.previous(observer),
      onStop: (observer) => {
        this.deltaY = 0
      },
      onWheel: (observer) => {
        // setTimeout(() => {
        //   this.deltaY = Math.abs(observer.deltaY)
        // }, 100)
        this.deltaY = Math.abs(observer.deltaY)
        console.log("onWheel", observer.deltaY, this.deltaY)
      },
      tolerance: 10,
      preventDefault: true,
    });    

    // interaction oberver when the bottom of element re-enters bottom of viewport we want to restart the animation
    ScrollTrigger.create({
      trigger: ".s-header-ktn",
      start: "bottom bottom",
      end: "bottom bottom",
      onEnterBack: () => this.restart(),
      // markers: true,
    });
  },

  methods: {
    skipAnimation() {
      if (this.animating) {
        return false;
      }
      console.log('skip')
      this.skip = true;
      this.animating = true;

      let tl = gsap.timeline({
        onComplete: () => { this.stop() }
      });

      // stage 1
      if (this.stage == 1) {
        this.intro(tl)
      }

      // skip the remaining stages
      for (let i = this.stage; i <= 8; i++) {
        this.animatePeopleGroup(tl, i)
      }      
      
    },

    backToTop() {
      if (this.animating) {
        return false;
      }
      console.log('back to top')
      this.skip = true;
      this.animating = true;

      let tl = gsap.timeline({
        onComplete: () => {
          this.animating = false
          setTimeout(() => this.observer.enable(), 500)
          console.log('back to top complete')
        }
      });

      // skip the remaining stages
      for (let i = this.stage-1; i >= 1; i--) {
        this.animatePeopleGroupReverse(tl, i)        
      }      

      // Fade in the main copy and move people svg back
      tl.to("#header-ktn-copy", {duration: 0.5*this.speed, opacity: 1, y: 0});
      tl.to("#people", {duration: 0.5*this.speed, y: 0, scale: 1}, "<");

      tl.to(window, {duration: 0.5, scrollTo:"header"});
      gsap.to(document.querySelector("#button-skip"), {opacity: 0, duration: 0.5*this.speed});
      gsap.to(document.querySelector("#button-back-to-top"), {opacity: 0, duration: 0.5*this.speed}, "<");

      this.stage = 0;
      this.skip = false;
      console.log('backToTop Finished')
    },


    restart() {
      if (this.stage >= 7) {
        this.animating = true;
        document.querySelector("body").classList.add("is-animating");
        this.observer.enable();
        
        let tl = gsap.timeline({
          onComplete: () => this.animating = false,
        });
        tl.to(window, {duration: 0.5, scrollTo:"#header-ktn-title"});
        tl.to(document.querySelector("#button-skip"), {opacity: 1, duration: 0.5*this.speed});
        tl.to(document.querySelector("#button-back-to-top"), {opacity: 1, duration: 0.5*this.speed}, "<");
        this.animatePeopleGroupReverse(tl, 8)
      }
    },
  
    intro (tl) {
      const titleRect = document.querySelector("#header-ktn-title h1").getBoundingClientRect()
      const peopleRect = document.querySelector("#people").getBoundingClientRect()
      const scale = window.innerWidth > 768 ? 0.85 : 1

      tl.to("#header-ktn-copy", {duration: 0.5*this.speed, opacity: 0, y: -100});
      tl.to("#people", {duration: 0.75*this.speed, y: titleRect.y - peopleRect.y - 30, scale: scale}, "<");
    },

    next(observer) {
      console.log("next", this.stage)
      this.animating = true;

      let tl = gsap.timeline({
        // onComplete: () => setTimeout(() => this.animating = false, 500)
        onComplete: () => { this.animating = false; }
      });

      if (this.stage == 0) {
        // scroll to title
        document.querySelector("body").classList.add("is-animating")
        tl.to(window, {duration: 0.5*this.speed, scrollTo:"#header-ktn-title"});
        tl.to(document.querySelector("#button-skip"), {opacity: 1, duration: 0.5*this.speed});
        tl.to(document.querySelector("#button-back-to-top"), {opacity: 1, duration: 0.5*this.speed}, "<");
      }

      if (this.stage == 1) {
        // Fade out the main copy 
        this.intro(tl)
      }

      if (this.stage >= 1) {
        this.animatePeopleGroup(tl, this.stage)
      }

      if (this.stage <= 7) {
        this.stage = this.stage + 1;
      } else {
        setTimeout(() => this.stop(), 1000);
      }
    },

    previous() {
      console.log('previous', this.stage)
      
      this.animating = true;

      let tl = gsap.timeline({
        // onComplete: () => setTimeout(() => this.animating = false, 500)
        onComplete: () => this.animating = false
      });

      if (this.stage == 0) {
        // this.observer.enable()
        tl.to(window, {duration: 0.5*this.speed, scrollTo:"header"});
        gsap.to(document.querySelector("#button-skip"), {opacity: 0, duration: 0.5*this.speed});
        gsap.to(document.querySelector("#button-back-to-top"), {opacity: 0, duration: 0.5*this.speed},"<");
      }

      if (this.stage == 1) {
        // this.stop();
        gsap.to(window, {duration: 0.5*this.speed, scrollTo: "header"});
      }

      if (this.stage >= 2) {
        this.animatePeopleGroupReverse(tl, this.stage - 1)
      }

      if (this.stage == 2) {
        // Fade in the main copy and move people svg back
        tl.to("#header-ktn-copy", {duration: 0.5*this.speed, opacity: 1, y: 0});
        tl.to("#people", {duration: 0.5*this.speed, y: 0, scale: 1}, "<");
      }

      if (this.stage <= 0) {
        this.observer.enable()
      } else {
        this.stage = this.stage - 1;
      }
    },    

    stop() {
      const body = document.querySelector("body")
      body.classList.remove("is-animating")
      gsap.to(document.querySelector("#button-skip"), {opacity: 0, duration: 0.5*this.speed});
      gsap.to(document.querySelector("#button-back-to-top"), {opacity: 0, duration: 0.5*this.speed},"<");
      this.observer.disable()
      this.skip = false
      this.stage = 8;
      const tl = gsap.timeline({
        onComplete: () => this.observer.disable()
      });

      // tl.to(".sections .section", {duration: 0.3, opacity: 0, y: "calc(100% + 100px)"});
    },

    animatePeopleGroup(timeline, group) {
      // make old section dissapear if there is one
      if (group > 1) {
        const oldSection = document.querySelector(`#section-${group-1}`)
        const oldSectionRect = oldSection.getBoundingClientRect()

        timeline
          .add('hide')
          .to(oldSection, {duration: 0.5*this.speed, opacity: 0, y: -100});
        // Fade out the previous group of people
        timeline
          .to(`#people #Group-${group-1} .first`, { duration: 0.5*this.speed, stopColor: "#47E3D9"}, 'hide')
          .to(`#people #Group-${group-1} .second`, { duration: 0.5*this.speed, stopColor: "#79FFD1"}, 'hide')
          .to(`#people #Group-${group-1} `, { duration: 0.5*this.speed, opacity: 0.14 }, 'hide')
      }

      const section = document.querySelector(`#section-${group}`)
      if (section) {
        // highlight new group of people
        timeline
          .add('highlight')
          .to(`#people #Group-${group} .first`, { duration: 0.5*this.speed, stopColor: "#DFA8FF"}, 'highlight')
          .to(`#people #Group-${group} .second`, { duration: 0.5*this.speed, stopColor: "#FF7038"}, 'highlight')

        // make new section appear
        timeline
          .to(`#section-${group}`, {duration: 0.5*this.speed, opacity: 1, y: 0}, 'highlight');
      }
    },

    animatePeopleGroupReverse(timeline, group) {
      const section = document.querySelector(`#section-${group}`)
      if (section) {
        const sectionRect = section.getBoundingClientRect()
        // highlight new group of people
        timeline
          .add('reverse-highlight')
          .to(`#people #Group-${group} .first`, { duration: 0.5*this.speed, stopColor: "#47E3D9"}, 'reverse-highlight')
          .to(`#people #Group-${group} .second`, { duration: 0.5*this.speed, stopColor: "#79FFD1"}, 'reverse-highlight')

        // make section dissapear
        timeline
          .to(`#section-${group}`, {duration: 0.5*this.speed, opacity: 1, y: sectionRect.height + 100}, 'reverse-highlight');
      }

      // make old section re-appear if there is one and highlight the group of people
      if (group > 1) {
        const oldSection = document.querySelector(`#section-${group-1}`)
        const oldSectionRect = oldSection.getBoundingClientRect()

        timeline
          .add('reappear', "<")
          .to(oldSection, {duration: 0.5*this.speed, opacity: 1, y: 0});
        
        timeline
          .to(`#people #Group-${group-1} .first`, { duration: 0.5*this.speed, stopColor: "#DFA8FF"}, 'reappear')
          .to(`#people #Group-${group-1} .second`, { duration: 0.5*this.speed, stopColor: "#FF7038"}, 'reappear')
          .to(`#people #Group-${group-1} `, { duration: 0.5*this.speed, opacity: 1 }, 'reappear')
      }


    },    
  }
}
</script>
<template>
  <div class="header-search">
    <form @submit.prevent="submit" class="search-form" :class="{active: active}">
      <ais-instant-search
        v-if="active"
        :search-client="searchClient"
        index-name="diaceutics_website_query_suggestions"
      >
        <ais-configure
          :hitsPerPage="5"
        />
        <ais-autocomplete>
          <template slot-scope="{ currentRefinement, indices, refine }">
            <vue-autosuggest
              v-model="query"
              :suggestions="indicesToSuggestions(indices)"
              :get-suggestion-value="getSuggestionValue"
              @keyup.enter="submit"
              @blur="disableSearch"
              @input="refine"
              :input-props="{
                id: 'search-input',
                placeholder: '',
              }"
            >
              <template slot-scope="{ suggestion }">
                <span class="suggestion" @mousedown="onSelect(suggestion.item.query)">{{ suggestion.item.query }}</span>
              </template>
            </vue-autosuggest>
          </template>
        </ais-autocomplete>
      </ais-instant-search>
    </form>
    <i :class="`icon icon--search${headerType == 'light' ? '--pink' : ''}${headerType == 'investor' ? '--investor' : ''}`" @click="handleSearchIcon" />
  </div>
</template>

<script>
import { AisInstantSearch, AisSearchBox, AisHits } from 'vue-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  props: {headerType: String, default: "light"},

  components: {
    AisInstantSearch,
    AisSearchBox,
    AisHits,
    VueAutosuggest
  },

  data() {
    return {
      query: "",
      searchClient: algoliasearch(
        window.ALGOLIA_APPLICATION_ID,
        window.ALGOLIA_SEARCH_KEY
      ),
      active: false,
    };
  },
  methods: {
    onSelect(query) {
      if (query) {
        this.query = query;
        this.submit()
      }
    },
    indicesToSuggestions(indices) {
      if (this.query && this.query.length > 0) {
        return indices.map(({ hits }) => ({ data: hits }));
      } else {
        return []
      }
    },
    getSuggestionValue(suggestion) {
        return suggestion.item.query;
    },    
    submit() {
      window.location = `/search?query=${this.query}`
    },
    handleSearchIcon() {
      if (this.active && this.query.length > 0) {
        this.submit()
      } else if (!this.active) {
        this.enableSearch()
      } else {
        this.disableSearch()
      } 
    },
    enableSearch() {
      document.getElementById('secondary-nav').classList.add("search-active")
      var vc = this;
      setTimeout( () => {
        vc.active = true
      }, 300)
      setTimeout( () => {
        document.getElementById("search-input").focus()
      }, 400)

    },
    disableSearch() {
      if (this.query && this.query.length > 0)
        return false

      setTimeout(() => {
        this.active = false
        setTimeout( () => {
          document.getElementById('secondary-nav').classList.remove("search-active")
        }, 300)
      }, 200)
    }
  },  
};
</script>

<style scoped lang="scss">
.header-search {
  color: black;
  margin-left: 20px;
  position:absolute;
  right: 0px;
  top: -5px;
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.search-form {
  background: #EEECF5;
  border-radius: 18px;
  padding: 10px 0px;
  width: 0px;
  transition: 0.3s;
  min-height: 37px;

  &.active {
    width: 250px;
    padding: 10px;
  }

}

.suggestion {
  display: inline-block;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 13px;
  color: rgba(0,0,0,0.8);
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style:  normal;
  font-weight: 400;
  

}
.icon--search, .icon--search--pink, .icon--search--investor {
  cursor: pointer;  
  margin-left: 19px;
  position: relative;
  top: 7px;
}

</style>

<style lang="scss">
.autosuggest__results {
  margin-top: 12px;
}
#search-input {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
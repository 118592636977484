<template>
  <form class="mailing-list-signup" @submit="onSubmit">
    <div v-if="success" class="success-message">Thanks, you're now signed up.</div>
      
    <template v-else>
      <input type="email" name="email" id="email" required v-model="email_signup.email" placeholder="Enter email address">
      <div class="error" v-if="errors.email">{{ errors.email }}</div>
      <button type="submit" class="button button--large button button--filled--blue">Sign Up</button>
    </template>
  </form>
</template>

<script>
import Api from "../utils/api";

export default {
  data: function() {
    return {
      email_signup: {
        email: '',
      },
      errors: {},
      success: false,
      isSending: false,
    }
  },

  methods: {
		onSubmit(evt) {
			evt.preventDefault();

      this.isSending = true;
      
      Api.post('/email_signups', { 
        email_signup: 
          {
            ...this.email_signup, 
          } 
        })
        .then((response) => {
          this.isSending = false;
          this.success = true;
        }).catch((e) => {
          this.isSending = false;
          console.log(e.response)
          if (e.response && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Sorry, something went wrong, please try again.")
          }
        });
	  	}
	}    
}
</script>

<style lang="scss" scoped>


  .success-enter-active, .success-leave-active {
    transition: opacity .5s;
  }
  
  .success-enter, .success-leave-to {
    opacity: 0;
  }

  .error {
    padding-top: 5px;
    font-size: 90%;
    color: #E9260F;
  }
</style>

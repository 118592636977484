<template>
  <section class="s-home-testimonials">
    <div class="m-testimonials">
      <div class="inner-wrapper">
        <div class="m-testimonials__line" v-if="category">
        </div>

        <div class="m-testimonials__nav" v-if="!category">
          <ul class="m-testimonials__nav__list">
            <li v-for="(category, index) in categories" :key="index">
              <span :class="['link', { 'active': category == selectedCategory }]" @click="handleCategorySwitch(category)">{{ category }}</span>
            </li>
          </ul>
        </div>

        <div class="m-testimonials__main">
          <flickity ref="flickity" :options="flickityOptions" :key="selectedCategory">
            <div class="carousel-cell" v-for="testimonial in filteredTestimonials" :key="testimonial.id">
              <div class="m-testimonials__slide">
                <div class="m-testimonials__slide__quote" v-html="testimonial.quote"></div>

                <div class="m-testimonials__slide__attribution">
                  <p class="attribution">
                    <span>{{ testimonial.attribution }}</span>
                    <template v-if="testimonial.role || testimonial.organisation">
                      {{ testimonial.role }}, {{ testimonial.organisation }}
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </flickity>

          <template v-if="filteredTestimonials.length > 1">
            <button @click="previous()" class="m-testimonials__button m-testimonials__button--prev">
              <i class="icon icon--carousel--prev"></i>
            </button>

            <button @click="next()" class="m-testimonials__button m-testimonials__button--next">
              <i class="icon icon--carousel--next"></i>
            </button>
          </template>
        </div>

        <div class="m-testimonials__read-more" v-if="content">
          <div class="read-more" v-html="content"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Flickity from 'vue-flickity';

export default {
  props: ["categories", "testimonials", "category", "content"],
  components: {
    Flickity,
  },
  data() {
    return {
      selectedCategory: this.category || this.categories[0],
      flickityOptions: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        adaptiveHeight: true
      }
    }
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },
    
    previous() {
      this.$refs.flickity.previous();
    },

    handleCategorySwitch(option) {
      this.selectedCategory = option;
    }
  },

  computed: {
    filteredTestimonials() {
      return this.testimonials.filter(test => test.category == this.selectedCategory);
    }
  }
}
</script>
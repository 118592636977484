<template>
  <div class="m-news m-news__index">
    <section class="m-news__filters">
      <div class="inner-wrapper">
        <div class="m-news__filters__title">
          <h1 class="title" v-if="section == 'investor'">Reports, Results &amp; Presentations</h1>
          <h1 class="title" v-else>Knowledge &amp; Insights</h1>
        </div>
        <div v-if="categoryOptions.length > 2">
          <ul class="m-news__filters__list">
            <li class="m-news__filters__list__item" v-for="category in categoryOptions" :key="category.id">
              <a @click="switchCategory(category)" :class="`m-news__filters__filter ${activeCategory.id == category.id ? 'active' : ''}`">
                {{ section == 'investor' ? category.investor_name : category.name}}
              </a>
            </li>
          </ul>
        </div>

        <div class="m-news__mobile-filters">
          <div :class="['m-news__mobile-filters__label', {'is-active' : mobileFiltersOpen}]" @click="toggleMobileFilters">
            <span class="label">{{ activeCategory.name }}</span>
            <i class="icon icon--arrow--down--blue "></i>
          </div>
          <ul class="m-news__mobile-filters__list" ref="mobileFilters">
            <li class="m-news__mobile-filters__list-item" v-for="category in categoryOptions" :key="category.id">
              <a @click="activeCategory=category" :class="`m-news__mobile-filters__filter ${activeCategory.id == category.id ? 'active' : ''}`">{{category.name}}</a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="m-news__section" v-if="!activeCategory.id && latestArticle">
      <div class="inner-wrapper">
        <a :href="latestArticle.url">

          <div 
            v-if="latestArticle.article_type == 'report'" 
            class="c-latest-news-card c-latest-news-card--report" 
            :class="latestArticle.image ? 'c-latest-news-card c-latest-news-card--report--custom-image' : ''" 
            >
            <author :article="latestArticle" />
            <report-title :article="latestArticle" v-if="latestArticle.image && latestArticle.display_report_title" />
            
            <div class="c-latest-news-card--report__custom-image" v-if="latestArticle.image">
              <img :src="latestArticle.image">
              </img>
            </div>

            <div class="c-latest-news-card--report__image-container"  v-if="!latestArticle.image">
              <div :class="`c-latest-news-card--report__image report-style-${latestArticle.report_style}`">
              </div>
              <div class="c-latest-news-card--report__text">
                <h1 class="title">{{latestArticle.title}}</h1>
                <span class="month-and-year">{{latestArticle.month}} {{latestArticle.year}}</span>
              </div>
            </div>


            <div class="c-latest-news-card--report__action">
              <span class="button button--filled--light">Read More</span>
            </div>
          </div>

          <div class="c-latest-news-card c-latest-news-card--primary" :style="`background-image: url('${latestArticle.image}')`" v-if="latestArticle.article_type == 'article'">
            <author :article="latestArticle" />

            <div class="c-latest-news-card__text-box c-latest-news-card__text-box--light">
              <span class="c-latest-news-card__label c-latest-news-card__label--blue">{{latestArticle.category_name}}</span>
              <div class="c-latest-news-card__title">
                <h3 class="title">{{latestArticle.title}}</h3>
              </div>
            </div>
          </div>

          <div 
            class="c-latest-news-card c-latest-news-card--primary c-latest-news-card--media-type" 
            :style="latestArticle.image ? `background-image: url('${latestArticle.image}')` : ''" 
            v-if="latestArticle.article_type == 'media'"
          >
            <author :article="latestArticle" />

            <div class="c-latest-news-card--media-type__play-button-container">
              <play-button></play-button>     
            </div>

            <div class="c-latest-news-card__text-box c-latest-news-card__text-box--dark">
              <span class="c-latest-news-card__label c-latest-news-card__label--pink">{{latestArticle.category_name}}</span>
              <div class="c-latest-news-card__title">
                <h3 class="title">{{latestArticle.title}}</h3>
              </div>
            </div>
          </div>

        </a>
      </div>
    </section>

    <section class="m-news__section" v-if="!activeCategory.id && featuredArticles && featuredArticles.length > 0">
      <div class="inner-wrapper">
        <h2 class="t-section-title">Featured</h2>
        <div class="grid grid--latest-news">
          <!-- themes  : ["epsilon", "gamma", "zeta", "none"] -->
          <div class="grid-col" v-for="article in featuredArticles" :key="article.id">
            <a :href="article.url">
              <template v-if="article.article_type == 'article'">
                <div class="c-latest-news-card" :style="`background-image: url('${article.featuredThumb}')`">
                  <author :article="article" />

                  <div :class="`c-latest-news-card__text-box c-latest-news-card__text-box--${article.theme == 'epsilon' ? 'dark' : 'light'}`">
                    <span class="c-latest-news-card__label c-latest-news-card__label--pink">{{article.category_name}}</span>
                    <div class="c-latest-news-card__title">
                      <h3 class="title">{{article.title}}</h3>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="article.article_type == 'media'">
                <div class="c-latest-news-card c-latest-news-card--media-type medium-size" :style="article.featuredThumb ? `background-image: url('${article.featuredThumb}')` : ''">
                  <author :article="article" />

                  <div class="c-latest-news-card--media-type__play-button-container">
                    <play-button></play-button>
                  </div>

                  <div :class="`c-latest-news-card__text-box c-latest-news-card__text-box--dark`">
                    <span class="c-latest-news-card__label c-latest-news-card__label--pink">{{article.category_name}}</span>
                    <div class="c-latest-news-card__title">
                      <h3 class="title">{{article.title}}</h3>
                    </div>
                  </div>
                </div>
              </template>              
              <template v-if="article.article_type == 'report'">
                <div class="c-featured-report-card">
                  <author :article="article" />
                  <report-title :article="article" v-if="article.image && article.display_report_title" />

                  <div 
                    v-if="article.image" 
                    class="c-featured-report-card__image-container c-featured-report-card__image-container__custom-image" 
                    :class="article.image && article.display_report_title ? 'c-featured-report-card__image-container__custom-image__display_report_title' : ''"
                    >
                    <img :src="article.image">
                    </img>
                  </div>

                  <div class="c-featured-report-card__image-container" v-if="!article.image">
                    <div class="c-featured-report-card__image" :class="`report-style-${article.report_style}`"></div>
                    <div class="c-featured-report-card__text">
                      <div class="c-featured-report-card__title">
                        <h3 class="title">{{article.title}}</h3>
                        <h4 class="month-and-year">{{ article.month }} {{ article.year }}</h4>
                      </div>
                    </div>
                  </div>
                  <!--                  
                  <div class="c-featured-report-card__action">
                    <span class="button button--filled--light">Read More</span>
                  </div>
                  -->
                </div>
              </template>
            </a>
          </div>
          
        </div>
      </div>
    </section>

    <section class="m-news__section">
      <div class="inner-wrapper">
        <div class="m-news__small-grid">
          <div class="grid grid--articles">

            <div class="grid-col" v-for="article in articles" :key="article.id">
              <a :href="article.url">

                <template v-if="article.article_type == 'article'">
                  <div class="c-small-news-card ">
                    <div v-if="!activeCategory.id" class="c-small-news-card__label">
                      <h5 class="label">{{article.category_name}}</h5>
                    </div>
                    <div class="c-small-news-card__title">
                      <div class="title">
                        <h4>{{article.title}}</h4>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="article.article_type == 'media'">
                  <div class="c-small-news-card c-small-news-card--media-type">
                    <div v-if="!activeCategory.id" class="c-small-news-card__label">
                      <h5 class="label">{{article.category_name}}</h5>
                    </div>
                    <div class="c-small-news-card__title">
                      <div class="title">
                        <h4>{{article.title}}</h4>
                      </div>
                    </div>
                  </div>
                </template>

                <template  v-if="article.article_type == 'report'">
                  <div class="c-small-news-card c-small-news-card--report">                    
                    <div v-if="!activeCategory.id" class="c-small-news-card__label">
                      <h6 class="label">{{article.category_name}}</h6>
                    </div>
                    <div class="c-small-news-card__title">
                      <div class="title">
                        <h4>{{article.title}}</h4>
                      </div>
                    </div>
                  </div>
                </template>

              </a>
            </div>

          </div>

          <div class="m-news__small-grid__show-more" v-if="hasMore">
            <button type="button" class="button button--hollow--blue" @click="fetchMore">Show More</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import Api from "../utils/api"
import Author from './author'
import PlayButton from './play-button'
import PlayButtonGrey from './play-button-grey'
import _ from 'lodash'

export default {
  props: ["categories", "section", "category"],

  components: {
    Author,
    PlayButton,
    PlayButtonGrey
  },

  data() {
    return {
      articles: [],
      latestArticle: null,
      featuredArticles: [],
      activeCategory: {id: null, name: "All", investor_name: "All", slug: ''},
      offset: 0,
      hasMore: false,
      loading: false,
      mobileFiltersOpen: false
    }
  },

  computed: {
    categoryOptions() {
      return _.sortBy([{id: null, position: 0, name: "All", investor_name: "All", slug: ''}, ...this.categories], 'position')
    },
    limit() {
      return this.activeCategory.id ? 6*4 : 3*4;
    }
  },

  mounted() {
    if (this.category) {
      this.switchCategory(this.category)
    } else {
      this.fetch();
      this.fetchFeatured()
    }
  },  

  watch: {
    activeCategory() {
      this.fetch()
      this.fetchFeatured()
    }
  },

  methods: {
    toggleMobileFilters() {
      const menu = this.$refs.mobileFilters;
      const menuHeight = menu.scrollHeight;
      
      if(!this.mobileFiltersOpen) {
        menu.style.maxHeight = menuHeight + 'px';
        this.mobileFiltersOpen = true;
      } else {
        menu.style.maxHeight = 0;
        this.mobileFiltersOpen = false;
      }
    },

    switchCategory(category) {
      this.activeCategory = category;
      const basePath = '/articles'
      window.history.replaceState({}, null, category.slug ? `${basePath}/category/${category.slug}` : basePath)
    },

    fetchFeatured() {
      Api.get('/articles', { params: { 
        category_id: this.activeCategory.id,
        section: this.section,
        featured: true,
        limit: 2,
      }})
        .then((response) => {
          this.featuredArticles = response.data.articles
        }).catch((e) => {
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    fetch() {
      this.loading = true
      this.hasMore = false
      Api.get('/articles', { params: { 
        category_id: this.activeCategory.id,
        section: this.section,        
        offset: 0,
        limit: this.limit + (this.activeCategory.id ? 0 : 1) // Plus one to grab the latest Article for the top, but only on the 'all' page
      }})
        .then((response) => {
          if (!this.activeCategory.id) {
            this.latestArticle = response.data.articles.shift()
          }
          this.articles = response.data.articles
          this.offset = (this.activeCategory.id ? 0 : 1)
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    fetchMore() {
      this.offset += this.limit;
      Api.get('/articles', { params: { 
        category_id: this.activeCategory.id,
        section: this.section,
        offset: this.offset,
        limit: this.limit
      }})
        .then((response) => {
          this.articles = [...this.articles, ...response.data.articles]
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });      
    },

    small(article) {
      return (article.title + article.month + article.year).length > 50 ? 'small' : ''
    }
  }
}
</script>
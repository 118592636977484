<template>
  <div class="ticker">
    <div class="label">
      Latest
    </div>
    <div class="items">
      <a :href="item.url" class="item" v-for="(item, index) in items" :key="index" :ref="`item-${index}`" :id="`item-${index}`"  :class="index == active ? 'active' : ''">
        {{ item.title }}
      </a>
    </div>
    <div class="overlay" ref="overflow"></div>
  </div>
</template>

<script>

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  props: {
    items: { type: Array, required: true }
  },

  data() {
    return {
      active: 0
    }
  },

  mounted() {
    this.start()
  },

  methods: {
    start() {
      setTimeout(this.next, 4000);
    },

    async next() {
      // find existing active item
      let item = this.$refs[`item-${this.active}`][0]

      // Check if the item is overflowing
      const overflow = item.scrollWidth - item.offsetWidth
      const scrollSpeed = 60; // 60px per second
      const scrollDuration = overflow / scrollSpeed;

      // if overflowing, scroll item to the left before fade transition, calculate scroll duration based on the distance to the left
      if (overflow > 0) {
        item.style.transition = `transform ${scrollDuration}s linear`
        item.style.transform = `translateX(-${overflow}px)`
        await sleep(scrollDuration * 1000) // await the scroll animation to finish
        this.$refs.overflow.style.opacity = 0;
        await sleep(1000) // wee pause at the end
      }
      
      // fade item out
      item.style.transition = `opacity 0.3s ease-in-out`
      item.classList.remove('active')
      await sleep(300)
      
      // move to the next item
      this.active = (this.active + 1) % this.items.length
      item = this.$refs[`item-${this.active}`][0]

      // fade next item in
      item.style.transition = `opacity 0.3s ease-in-out`
      item.style.transform = `translateX(0px)` // reset to the start of the left
      item.classList.add('active')

      // display the overlay if this next item is overflowing
      this.$refs.overflow.style.opacity = item.scrollWidth - item.offsetWidth > 0 ? 1 : 0; 

      // schedule timer for next item
      setTimeout(this.next, 3000);
    }
 },
}
</script>

<style lang="scss" scoped>
.ticker {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 42px;
}
.label {
  color: #DFA9FF;
  font-family: 'Inter-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 12px;
  height: 14px;
  padding-right: 16px;
  border-right: 1px solid rgba(255,255,255,0.4);
  margin-right: 16px;
}
.items {
  position: relative;
  width: 100%;
  height: 14px;
  white-space: nowrap;
  overflow: hidden;

  .item {
    width: calc(100% - 20px);
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 400;
    padding: 0;
    border: 0;
    text-decoration: none;
    
    font-size: 12px;
    height: 14px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    color: white;

    &.active {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

.overlay {
  height: 42px;
  width: 42px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-image: linear-gradient(to right, rgba(22,21,46,0), rgba(22,21,46,1));

  @media (max-width: 768px) {
    transition: opacity 0.3s ease-in-out;
  }
  
}

</style>
<template>
  <video class="bg-video" autoplay loop muted preload @canplay="canPlay = true" :class="canPlay ? 'can-play' : '' ">
    <source :src="url" type="video/mp4"  />
  </video>
</template>

<script>
export default {
  props: {
    url: {type: String, required: true}
  },

  data() {
    return {
      canPlay: false
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="tag-container">

    <div class="tag-results-title">
      <h2 class="tag-results-count">{{ tag.articles_count }} Results <span>for</span></h2>
      <h1 class="tag-results-query">{{ tag.name }}</h1>
    </div>

      <div class="tag-results">
        <a v-for="(article, index) in articles" :key="index" :href="`${article.url}`">
          <div class="tag-result">
            <h4 class="tag-result-title">{{ article.title }}</h4>
            <div class="tag-result-body">
              {{ article.summary }}
            </div>
          </div>
        </a> 
      </div>

      <div class="tag-results-show-more">
        <div class="tag-results-stats" v-if="tag.articles_count > 0">
          Showing {{ articles.length }} of {{ tag.articles_count }} Results
        </div>
        <button v-if="hasMore" @click="fetchMore" class="tag-results-show-more-button" >
          Show More
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../utils/api"
export default {
  props: {
    tag: Object,
  },

  data() {
    return {
      articles: [],
      offset: 0,
      limit: 8,
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true
      this.hasMore = false
      Api.get('/articles', { params: { 
        tag_id: this.tag.id,
        offset: 0,
        limit: this.limit
      }})
        .then((response) => {
          this.articles = response.data.articles
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    fetchMore() {
      this.offset += this.limit;
      Api.get('/articles', { params: { 
        tag_id: this.tag.id,
        offset: this.offset,
        limit: this.limit
      }})
        .then((response) => {
          this.articles = [...this.articles, ...response.data.articles]
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });      
    },     
  }
};
</script>

<style scoped lang="scss">
.tag-container {
  padding: 0px;
}
.tag-results-title {
  .tag-results-count {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 700;
    
    font-size: 16px;
    line-height: 33px;
    color: #3256FF;

    span {
      color: #c2c2dc;
    }
  }
  .tag-results-query {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 700;
    
    font-size: 31px;
    line-height: 33px;
    color: #16152E; 
    margin-bottom: 120px;
    margin-top: 26px;
  }
}
.tag-results {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px;
  grid-row-gap: 60px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}
.tag-result {
  padding-top: 25px;
  border-top: 1px solid #DBDBDB;
  cursor: pointer;

  .tag-result-title {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 700;
    
    font-size: 16px;
    line-height: 29px;
    color: #16152E;
  }

  .tag-result-type {
    background-color: #F4E9FB;
    color: #CB93ED;
    display: inline-block;
    padding: 10px 13px;
    margin-top: 12px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-family: 'Inter-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 12px;
  }
  .tag-result-body {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 400;
    
    font-size: 14px;
    line-height: 25px;
    color: #6d6d7e;
  }
}
.tag-results-show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 120px;
}
.tag-results-stats {
  color: #c2c2dc;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style:  normal;
  font-weight: 700;
  
  font-size: 14px;
  line-height: 25px;
  margin-top: 75px;
  margin-bottom: 20px;
}

.tag-results-show-more-button {
  display: block;
  padding: 20px 27px;
  border: 1px solid #DFA9FF;
  border-radius: 30px;
  cursor: pointer;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style:  normal;
  font-weight: 500;
  
  font-size: 13px;
  color: black;
}
</style>
<style lang="scss">
.ais-Snippet-highlighted {
  // font-weight: bold;
  background-color: #f7f2a3;
}
</style>
<template>
  <div class="countdown">
    <div class="left">
      <h2 class="title t-large">Launching in</h2>
    </div>
    <div class="right">
      <countdown :time="time" tag="div">
        <template slot-scope="props">
          <ul class="countdown-timer">
            <li>
              <span class="value">{{ props.days }}</span>
              <span class="label">Days</span>
            </li>
            <li>
              <span class="value">{{ props.hours }}</span>
              <span class="label">Hours</span>
            </li>
            <li>
              <span class="value">{{ props.minutes }}</span>
              <span class="label">Mins</span>
            </li>
            <li>
              <span class="value">{{ props.seconds }}</span>
              <span class="label">Secs</span>
            </li>
          </ul>
        </template>
      </countdown>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hide {
  visibility: hidden;
}
</style>

<script>
import Vue from "vue";
import Countdown from "@chenfengyuan/vue-countdown";

export default {
  props: ["launchingIn"],
  components: {
    Countdown,
  },
  data() {
    var now = new Date();
    var launching_in = new Date(this.launchingIn);
    return {
      counting: false,
      time: launching_in - now,
    };
  },
};
</script>

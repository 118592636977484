<template>
  <div class="report-title" v-if="article.image">
    <div class="details">
      <p class="title">{{ article.title }}</p>
      <p class="month-year">{{article.month}} {{article.year}}</p>
    </div>
  </div>

</template>

<script>
export default {
  props: ["article"],
}
</script>

<style lang="scss" scoped>
  .report-title {
    position: absolute;
    top: 28px;
    left: 28px;
    z-index: 1;
    display: flex;
    align-items: center;

    @media screen and (max-width: 479px) {
      position: relative;
      top: 0px;
      left: 0px;
      padding-bottom: 28px;
      align-self: flex-start;
    }

  }

  .details {
    .title,
    .month-year {
      font-size: 14px;
      line-height: 27px;
      font-family: 'Inter', Arial, Helvetica, sans-serif;
      font-style:  normal;
      font-weight: 600;
    }

    .title {
      color: #16152E;
      font-weight: 700;
    }

    .month-year {
      color: #3256FF;
    }
  }
</style>
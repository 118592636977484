<template>
  <div class="cta-bar" :class="['container', {'is-visible': visible }, {'is-sticky': sticky }]">
    <div class="prompt" :style="{backgroundImage: background ? 'url(' + background + ')' : null}">
      <div class="prompt__left">
        <p class="prompt__title">{{ name }}</p>
      </div>

      <div class="prompt__right"><slot></slot></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../packs/event-bus';
export default {
  props: ['name','children','background'],
  data() {
    return {
      visible: false,
      sticky: false,
    }
  },
  mounted() {
    EventBus.$on('toggleCtaPrompt', (payload) => {
      if(payload.visibility == 'hide') {
        this.visible = false;
      } else if(payload.visibility == 'show') {
        this.visible = true;
      }
    })

    EventBus.$on('toggleCtaPromptSticky', (payload) => {
      this.sticky = payload;
    })    
  }
}
</script>

<style lang="scss" scoped>
  .container {
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    z-index: 5;
    transition: transform .6s cubic-bezier(.25,1.34,.65,1.01);

    &.is-visible {
      transform: translateY(-120%);
    }

    &.is-sticky {
      transform: translateY(-120%);
      position: static;      
      top: auto;
      left: auto;
      right: auto;
      width: 100%;
      margin-bottom: -100px;      
      height: 100px;
    }

    @media screen and (max-width: 479px) {
      height: auto;
    }
  }

  .prompt {
    height: 100%;
    background: #E7E7E7; 
    background-image: url('../../assets/images/backgrounds/report-download-prompt-bg.png');
    background-size: cover;
    background-position: center;
    border-radius: 21px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      padding: 0 28px;
      justify-content: center;
    }

    @media screen and (max-width: 479px) {
      padding: 18px;
    }
  }

  .prompt__left {
    padding-right: 14px;
  }

  .prompt__title {
    font-family: 'Inter-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 18px;
    // color: #19193B;
    color: white;
    letter-spacing: 0;
    line-height: 32px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
</style>
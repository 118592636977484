<template>
  <div class="search-container">

    <ais-instant-search
      :index-name="index"
      :search-client="searchClient"
      :search-function="searchFunction"
    >
      <ais-configure 
        :attributesToSnippet="['body:30', 'title:30']"
        :hitsPerPage="8"
        />

      <ais-state-results>
        <div
          slot-scope="{ state: { query }, results: { nbHits } }"
          class="search-results-title"
        >
          <h2 class="search-results-count">{{ nbHits }} Results <span>for</span></h2>
          <h1 class="search-results-query">{{ query }}</h1>
        </div>
      </ais-state-results>

      <ais-infinite-hits>
        <div slot-scope="{ items, results, refineNext, isLastPage }">
          <div class="search-results">
            <a v-for="(item, index) in items" :key="index" :href="`${path(item)}`" :target="item.type == 'external_page' ? '_blank' : ''" >
              <div class="search-result">
                <h4 class="search-result-title">{{ item.title }}</h4>
                <div class="search-result-type">{{ inflection.titleize(item.type) }}
                </div>
                <div class="search-result-body">
                  <ais-snippet attribute="body" :hit="item" />
                </div>
              </div>
            </a> 
          </div>

          <div class="search-results-show-more">
            <div class="search-results-stats" v-if="results.nbHits > 0">
              Showing {{ items.length }} of {{ results.nbHits }} Results
            </div>
            <button v-if="!isLastPage" @click="refineNext" class="search-results-show-more-button" >
              Show More
            </button>
          </div>
        </div>
      </ais-infinite-hits>
    </ais-instant-search>
  </div>
</template>

<script>
import { AisInstantSearch, AisSearchBox, AisHits } from 'vue-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import inflection from 'inflection'

export default {
  props: {
    query: String,
    index: String
  },

  components: {
    AisInstantSearch,
    AisSearchBox,
    AisHits
  },

  data() {
    return {
      searchClient: algoliasearch(
        window.ALGOLIA_APPLICATION_ID,
        window.ALGOLIA_SEARCH_KEY
      ),
      inflection: inflection,
    };
  },
  methods: {
     searchFunction(helper) {
       if (this.query && this.query.length > 0) {
          const page = helper.getPage(); // Retrieve the current page

          helper.setQuery(this.query) // this call resets the page
                .setPage(page) // we re-apply the previous page
                .search();
       }
    },

    path(item) {
      if (item.type === 'page') {
        return item.slug
      }
      if (item.type === 'article') {
        return `/articles/${item.slug}`
      }
      if (item.type === 'team_member') {
        return `/team_members/${item.slug}`
      }
      if (item.type === 'external_page') {
        return item.url
      }
    }
  }
};
</script>

<style scoped lang="scss">
.search-container {
  padding: 0px;
}
.search-results-title {
  .search-results-count {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 700;
    
    font-size: 16px;
    line-height: 33px;
    color: #3256FF;

    span {
      color: #c2c2dc;
    }
  }
  .search-results-query {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 700;
    
    font-size: 31px;
    line-height: 33px;
    color: #16152E; 
    margin-bottom: 120px;
    margin-top: 26px;
  }
}
.search-results {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px;
  grid-row-gap: 60px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}
.search-result {
  padding-top: 25px;
  border-top: 1px solid #DBDBDB;
  cursor: pointer;

  .search-result-title {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 700;
    
    font-size: 16px;
    line-height: 29px;
    color: #16152E;
  }

  .search-result-type {
    background-color: #F4E9FB;
    color: #CB93ED;
    display: inline-block;
    padding: 10px 13px;
    margin-top: 12px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-family: 'Inter-SemiBold', Arial, Helvetica, sans-serif;
    font-size: 12px;
  }
  .search-result-body {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 400;
    
    font-size: 14px;
    line-height: 25px;
    color: #6d6d7e;
  }
}
.search-results-show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 120px;
}
.search-results-stats {
  color: #c2c2dc;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style:  normal;
  font-weight: 700;
  
  font-size: 14px;
  line-height: 25px;
  margin-top: 75px;
  margin-bottom: 20px;
}

.search-results-show-more-button {
  display: block;
  padding: 20px 27px;
  border: 1px solid #DFA9FF;
  border-radius: 30px;
  cursor: pointer;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style:  normal;
  font-weight: 500;
  
  font-size: 13px;
  color: black;
}
</style>
<style lang="scss">
.ais-Snippet-highlighted {
  // font-weight: bold;
  background-color: #f7f2a3;
}
</style>
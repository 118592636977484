<template>
  <section
    class="s-header-carousel"
    :class="`previous-${previousIndex} position-${currentIndex}`"
  >
    <div
      class="m-header-carousel"
      :class="`previous-${previousIndex} position-${currentIndex}`"
    >
      <div class="inner-wrapper desktop">
        <div class="m-header-carousel__copy-container">
          <div
            class="card"
            :class="`card-${index}`"
            :key="`card-${index}`"
            v-for="(slide, index) in carousel"
          >
            <div class="m-hero__title">
              <h1 class="title">{{ slide.subHeading }}</h1>
            </div>
            <p class="copy">
              {{ slide.title }}
            </p>
            <div class="m-hero__action">
              <a
                role="button"
                class="button button--filled--blue"
                :href="slide.btnUrl"
                >{{ slide.btnText }}</a
              >
            </div>
          </div>
        </div>
        <div class="m-header-carousel__images-container">
          <ol class="slide-row" ref="slideRowRef">
            <li
              class="slide"
              :class="`slide-${index}`"
              v-for="(slide, index) in carousel"
              :key="`slide-${index}`"
              @click="nextSlide()"
              ref="slideRef"
            >
              <div class="image" v-html="slide.imageTag"></div>
            </li>
          </ol>
        </div>
      </div>

      <div class="mobile">
        <flickity ref="flickity" :options="flickityOptions">
          <div
            class="mobile-card"
            :key="`mobile-card-${index}`"
            v-for="(slide, index) in carousel"
          >
            <div class="image inner-wrapper">
              <img :src="slide.imageSmall" :alt="slide.alt" />
            </div>
            <div class="mobile-card__copy-section inner-wrapper">
              <div class="m-hero__title">
                <h1 class="title">{{ slide.subHeading }}</h1>
              </div>
              <p class="copy">
                {{ slide.title }}
              </p>
              <div class="m-hero__action">
                <a
                  role="button"
                class="button button--filled--blue"
                  :href="slide.btnUrl"
                  >{{ slide.btnText }}</a
                >
              </div>
            </div>

            <!-- <div class="image" v-html="slide.image"></div> -->
          </div>
        </flickity>
      </div>
      <div class="progress" v-if="carousel.length > 1">
        <ol class="progress__page-dots">
          <li
            v-for="(slide, index) in carousel"
            class="dot-target"
            :key="`dot-${index}`"
            @click="goToSlide(index)"
            aria-current="step"
          >
            <i class="dot" :class="`dot-${index}`"></i>
          </li>
          <li class="carousel-arrow bounce" @click="nextSlide()">
            <svg
              version="1.1"
              viewBox="0 0 26 12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <g transform="translate(-1086 -688)" stroke="#fff">
                  <g
                    transform="translate(1099 694) rotate(180) translate(-12 -5)"
                  >
                    <polyline id="a" points="5 10 0 5 5 0" />
                    <line x2="24" y1="5" y2="5" />
                  </g>
                </g>
              </g>
            </svg>
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script>
import Flickity from "vue-flickity";
export default {
  props: ["data"],
  components: {
    Flickity,
  },
  data() {
    return {
      carousel: this.data,
      currentIndex: 0,
      previousIndex: 0,
      flickityOptions: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        adaptiveHeight: true,
      },
    };
  },
  mounted() {
    this.$refs.flickity.on("change", (o) => {
      this.currentIndex = o;
    });
  },
  watch: {
    currentIndex(newIndex, oldIndex) {
      this.previousIndex = oldIndex;
      this.$refs.flickity.select(newIndex);
    },
  },
  methods: {
    nextSlide() {
      if (this.currentIndex == this.carousel.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
    goToSlide(slideIndex) {
      this.currentIndex = slideIndex;
    },
  },
};
</script>

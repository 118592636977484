<template>
  <section id="id" class="s-header-ktn">
    <div class="m-hero m-hero--ktn">
      <div class="m-hero__container">
        <div class="inner-wrapper">
          <div class="m-hero__content">
            <div id="header-ktn-title" class="m-hero__title">
              <h1 class="title">
              {{ title }}
              </h1>
            </div>
            <div id="header-ktn-copy" class="m-hero__copy wysiwyg" v-html="content" >
            </div>
            <div id="people" class="people">
              <div style="border:2px solid red; height: 40vh; width: 40vh; margin-top: 100px;">
              </div>
            </div>
          </div>

          <div class="sections" id="sections">
            <div class="section" v-for="(section, i) in sections" :key="i">
              <div class="section__content">
                <div class="section__number">{{ i + 1}} </div>
                <div class="section__title">{{ section.title }}</div>
                <div class="section__copy wysiwyg" v-html="section.content"></div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>

</template>

<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  props: ['id','title','content', 'sections'],
  
  mounted() {
    this.loadAnimation();
  },

  methods: {
    // This is called when the SVG is mounted as it hooks into SVG Groups
    loadAnimation() {
      this.titleCopyAnimation();
      this.peopleScaleAnimation();
      this.sectionAnimation()
    },

    titleCopyAnimation() {
      let st = ScrollTrigger.create({
        trigger: "#header-ktn-title",
        pin: "#header-ktn-title",
        anticipatePin: 0,
        start: "top top",
        end: () => "+=" + (document.querySelector(".sections").offsetHeight + 320),
        invalidateOnRefresh: true,
        // markers: true
      });

       // Fade out the main copy 
      let tl = gsap.timeline({
        scrollTrigger: {
          scrub: true,
          trigger: "#header-ktn-title",
          pin: "#header-ktn-copy",
          anticipatePin: 0,
          start: "top top",
          end: "+=200",
          // markers: true
        }
      });     
      tl.to("#header-ktn-copy", { autoAlpha: 0 }) 
    },

    peopleScaleAnimation() {
      let people_tl = gsap.timeline({
        scrollTrigger: {
          scrub: true,
          trigger: "#people",
          pin: "#people",
          anticipatePin: 0,
          start: "top top",
          end: () => "+=" + document.querySelector(".sections").offsetHeight,
          // markers: true
        }
      });     
      
      if (window.innerWidth > 768) {
        let tl3 = gsap.timeline({
          scrollTrigger: {
            scrub: true,
            trigger: "#header-ktn-title",
            start: "top top",
            end: "+=300",
            // markers: true
          }
        });  
        tl3.to("#people .svg", { duration: 1, scale: 0.85, transformOrigin:"50% 0%" }) 
      }
    },

    sectionAnimation() {
      const targets = document.querySelectorAll(".sections .section");
      targets.forEach((target, i) => {
        

        let max = Math.max(...Array.from(document.querySelectorAll(".sections .section__content")).map(s => s.offsetHeight))
        max = max + 20 // addtional spacing
        console.log('Max', Math.max(max, window.innerHeight * 0.22))

        // people_height = document.querySelector(".people").getBoundingClientRect().height

        gsap.timeline({
          scrollTrigger: {
            pin: target,
            pinSpacing: false,
            scrub: true,
            anticipatePin: 0,
            // start: () => `top ${document.querySelector("#people").offsetHeight}`,
            // start: "top bottom-=270px",
            start: `top bottom-=${Math.max(max, window.innerHeight * 0)}px`,
            end: () => `+=${target.offsetHeight}`,
            // end: () => "+=" + target.offsetHeight,
            trigger: target,
            markers: true,
          }
        });

        const timeline = gsap.timeline({
          scrollTrigger: {
            scrub: true,
            // start: () => `top ${document.querySelector("#people").offsetHeight}`,
            start: "top bottom",
            end: () => "+=" + target.offsetHeight,
            trigger: target,
            // markers: true,
          }
        });

        timeline
          .add('highlight')
          .to(`#Group-${i+1} .first`, { duration: 2, stopColor: "#DFA8FF"}, 'highlight')
          .to(`#Group-${i+1} .second`, { duration: 2, stopColor: "#FF7038"}, 'highlight')

        timeline
          .add('back', '+=2')
          .to(`#Group-${i+1} .first`, { duration: 1, stopColor: "#47E3D9"}, 'back+=1')
          .to(`#Group-${i+1} .second`, { duration: 1, stopColor: "#79FFD1"}, 'back+=1')

        timeline
          .from(`#Group-${i+1}`, { duration: 1, opacity: 1 }, 'back')
          .to(`#Group-${i+1} `, { duration: 1, opacity: 0.2 }, 'back+=1')
        // fade out
        // if (i < 6) {
          timeline
            .from(target, { duration: 1, opacity: 1, yPercent: 0 }, 'back')
            .to(target, { duration: 1, opacity: 0, yPercent: -10}, 'back+=1')
        // }
      
      })
    }
  }
}
</script>
<template>
  <div class="header-search-mobile">
    <form @submit.prevent="submit" class="search-form">
      <ais-instant-search
        :search-client="searchClient"
        :search-function="searchFunction"
        index-name="diaceutics_website_query_suggestions"
      >
        <ais-configure
          :hitsPerPage="5"
        />
        <span class="place-holder" v-if="!active" @click="setActive">Tap to search</span>
        <ais-autocomplete v-if="active">
          <template slot-scope="{ currentRefinement, indices, refine }">
            <vue-autosuggest
              v-model="query"
              :suggestions="indicesToSuggestions(indices)"
              :get-suggestion-value="getSuggestionValue"
              @keyup.enter="submit"
              @input="refine"
              :input-props="{
                id: 'search-input-mobile',
                placeholder: 'Tap to search',
                autoFocus: true,
              }"
            >
              <template slot-scope="{ suggestion }">
                <span class="suggestion" @mousedown="onSelect(suggestion.item.query)">{{ suggestion.item.query }}</span>
              </template>
            </vue-autosuggest>
          </template>
        </ais-autocomplete>
      </ais-instant-search>
    </form>
    <i class="icon icon--search--mobile" />
  </div>
</template>

<script>
import { AisInstantSearch, AisSearchBox, AisHits } from 'vue-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  components: {
    AisInstantSearch,
    AisSearchBox,
    AisHits,
    VueAutosuggest
  },

  data() {
    return {
      query: "",
      searchClient: algoliasearch(
        window.ALGOLIA_APPLICATION_ID,
        window.ALGOLIA_SEARCH_KEY
      ),
      active: false,
    };
  },

  watch: {
    active(value) {
      if (value) {
        this.$nextTick(() => {
          setTimeout(() => document.getElementById('search-input-mobile').focus(), 500)
        })
      }
    }
  },

  methods: {
    setActive () {
      this.active = true
    },
    searchFunction(helper) {
      // console.log('searchFunction', helper.state.query)
      // helper.state.query
      if (this.active) {
        helper.search();
      }
    },    
    onSelect(query) {
      if (query) {
        this.query = query;
        this.submit()
      }
    },
    indicesToSuggestions(indices) {
      if (this.query && this.query.length > 0) {
        return indices.map(({ hits }) => ({ data: hits }));
      } else {
        return []
      }
    },
    getSuggestionValue(suggestion) {
        return suggestion.item.query;
    },    
    submit() {
      window.location = `/search?query=${this.query}`
    },
  },  
};
</script>

<style scoped lang="scss">
.header-search-mobile {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // margin-top: 40px;

  .place-holder {
    margin-left: 10px;
    width: 100%;
    opacity: 0.4;
    font-size: 16px;
  }

  .search-form {
    background: #363550;
    color: #fff;
    border-radius: 18px;
    padding: 10px 0px;
    width: 100%;
    padding: 10px;
    margin-right: -16px;
    max-width: 350px;
    min-height: 39px;
  }

  .suggestion {
    display: inline-block;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 13px;
    color: #fff;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style:  normal;
    font-weight: 400;
    
  }
  .icon--search--mobile {
    position: relative;
    left: -20px;
    top: 11px;
  }
}
</style>

<style lang="scss">
.autosuggest__results {
  margin-top: 12px;
}
#search-input-mobile {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-size: 16px;
}
</style>